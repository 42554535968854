import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  claims: any[];

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService,public authService: AuthenticationService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
    this.claims = this.authService.currentUserValue.claims;

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }

  checkUserClaim(claim) {
    var item = this.claims.find(c => (c.type == claim.id && c.value == "View"));

    if (item || claim.id == "All") {
      //console.log(claim.title, "exist");
      return true;
    }
    //console.log(claim.title, "not exist");
    return false;
  }

  filterMenuByClaims(menu: any[]) {

    let filterdMenu = [];
    let claims: any[] = this.authService.currentUserValue.claims;

    for (let i = 0; i < menu.length; i++) {
      filterdMenu.push(menu[i]);
      //console.log(filterdMenu);
      for (let j = 0; j < menu[i].children.length; j++) {
        claims.filter(c => {
          if (c.type == menu[i].children[j].title) {
            filterdMenu.push(menu[i].children[j]);
            filterdMenu[i].children = menu[i].children[j];
          }
        })
      }
    }

    //console.log(filterdMenu, "filterdMenu");
    this.menu = filterdMenu;
  }

  checkSection(item) {

    console.log(item)

    let userClaims: any[] = this.authService.currentUserValue.claims;
    //  //console.log(userClaims, "userClaims")
    let existItems = [];

    userClaims = userClaims.filter(e => e.value == "View");
    //console.log(userClaims, "userClaims view")
    userClaims.forEach(element => {

      let existItem = item.children.find(e => element.type == e.id);
      if (existItem) {
        existItems.push(existItem);
      }
    });

    //console.log(item, "item");
    //console.log(existItems, "existItems");

    if (existItems.length > 0) {
      return true;
    }

    return false;
  }
}
