import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let error = "";

        if ([401, 403].indexOf(err.status) != -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.clear();
          window.location.href = '/pages/authentication/login-v2';
        }

        if ([400].indexOf(err.status) !== -1) {

          console.log(err);

          if (err?.error?.message) {
            error = err?.response?.data?.error?.message;
          } else {
            Object.keys(err?.error?.errors).forEach(function (key, index) {
              // key: the name of the object key
              // index: the ordinal position of the key within the object
              error += key + ":" +err?.error?.errors[key] + "<br/>";
            });
          }
        }
        // throwError
      //  const error = err.error.message || err.statusText || err.errors;
        return throwError(error);
      })
    );
  }
}
