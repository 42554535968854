import { Branch } from '@core/types/branches';
import { Role } from './role';


export interface Users {
  items: User[];
  totalCount: number;
}
export class User {
  id: number;
  userName: string;
  imagePath: string;
  roles: any[Role];
  refreshToken?: string;
  email: string;
  enabled: boolean;
  branch: Branch;
  phoneNumber: string;
  departmentsValue: number[];
  accessToken?: string;
  claims: any[];
  password: string
}
// export class User {
//   id: number;
//   email: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   avatar: string;
//   role: Role;
//   token?: string;
// }
