import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private http: HttpClient) { }

  getJsonData(): Observable<any> {
    return this.http.get<any>('../../assets/version.json').pipe(
      map(data => {
        // Transform data here
        return {
          // Assume the JSON data has properties you want to transform or restructure
          version: data.version
        };
      })
    );
  }
}
